var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("grades-component", {
    attrs: { course_id: _vm.course_id, batch_id: _vm.batch_id },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }