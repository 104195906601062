<template>
  <div v-if="!renderNow" id="loading-wrapper">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
  <v-container v-else>
    <page-title
      :page-title="!$language ? 'Grades' : 'গ্রেড'"
      class="mt-5"
    ></page-title>
    <div v-if="resultPublished">
      <div class="ml-14 mt-14">
        <div style="font-size: 18px">
          {{ !$language ? "You have got" : "তুমি" }} {{ result.grade }}
          {{ !$language ? "" : "পেয়েছো" }}
        </div>
        <v-btn :loading="fetching" @click="viewCertificate">{{
          !$language ? "View Certificate" : "সার্টিফিকেট দেখো"
        }}</v-btn>
        <v-dialog v-model="preview">
          <file-previewer
            v-if="preview"
            :preview-url="certificate.url"
            :download-url="certificate.url"
            :title="!$language ? 'Certificate' : 'সার্টিফিকেট'"
            :is-video="false"
            :is-download="false"
            @cancel="preview = false"
          ></file-previewer>
        </v-dialog>

        <v-row class="mt-6">
          <span class="ml-4"
            >{{ !$language ? "Total Grade" : "মোট গ্রেড" }}:
            <span style="color: #0099dc" class="ml-2">{{
              result.grade
            }}</span></span
          >
          <span class="ml-10"
            >{{ !$language ? "Total Marks" : "মোট নম্বর" }}:
            {{ result.percentage.toFixed(2) }}/100</span
          >
        </v-row>
      </div>
      <div class="ml-14 mt-14">
        <div class="mt-6">
          {{ !$language ? "Detailed Marks" : "বিস্তারিত নম্বর" }}:
        </div>
        <div class="mt-6">
          {{ !$language ? "Assignments" : "অ্যাসাইনমেন্ট" }}
        </div>
        <v-card outlined class="mt-6">
          <div
            v-for="assignment in batchAssignments"
            :key="assignment.object_id"
            class="ma-8 ml-6"
          >
            {{ assignment.title }} ({{ assignment.total_score }})
            <div>{{ getAssignmentGrade(assignment.object_id) }}</div>
          </div>
        </v-card>
        <div class="mt-6">{{ !$language ? "Exams" : "পরীক্ষা" }}</div>
        <v-card outlined class="mt-6">
          <div
            v-for="exam in batchExams.exams"
            :key="exam.id"
            class="ml-6 my-8"
          >
            {{ exam.title }} ({{ exam.total_points }})
            <div>{{ getExamMark(exam.id) }}</div>
          </div>
        </v-card>
        <div class="mt-6">{{ !$language ? "Projects" : "প্রজেক্ট" }}</div>
        <v-card outlined class="mt-6">
          <div
            v-for="project in batchProjects"
            :key="project.object_id"
            class="ml-6 my-8"
          >
            {{ project.title }} ({{ project.total_score }})
            <div>{{ getProjectMark(project.object_id) }}</div>
          </div>
        </v-card>
      </div>
    </div>
    <no-content-component
      v-else
      class="mt-14"
      :message="noContentMessage"
    ></no-content-component>
  </v-container>
</template>

<script>
import FilePreviewer from "~ef/components/FilePreviewer";
import gradeService from "./services/GradeService";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "GradesComponent",
  components: { FilePreviewer, NoContentComponent },
  props: {
    course_id: {
      Type: String,
      required: true,
    },
    batch_id: {
      Type: String,
      required: true,
    },
  },
  data() {
    return {
      certificate: {},
      preview: false,
      fetching: false,
      renderNow: false,
      resultPublished: false,
      batchExams: undefined,
      examMarks: undefined,
      batchAssignments: undefined,
      assignmentMarks: undefined,
      batchProjects: undefined,
      projectMarks: undefined,
      result: undefined,
      markDetails: undefined,
    };
  },
  computed: {
    noContentMessage() {
      return !this.$language
        ? "Your result is not available right now."
        : "এই মুহূর্তে আপনার কোনো প্রকাশিত ফলাফল নেই।";
    },
    hasGrades(){
      if(this.result instanceof Object) return "grade" in this.result;
      else return false;
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.renderNow = true;
      return;
    }
    await this.getResult();
  },
  methods: {
    async fetchURL() {
      this.fetching = true;
      this.$logger.log("fetching");
      try {
        this.certificate = await this.$store.dispatch("certificate/get", {
          courseId: this.course_id,
          batchId: this.batch_id,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        throw e;
      } finally {
        this.fetching = false;
      }
    },
    async viewCertificate() {
      if (!this.certificate.url) {
        try {
          await this.fetchURL();
          this.preview = true;
        } catch (e) {
          this.preview = false;
        }
      } else {
        this.preview = true;
      }
    },
    getAssignmentGrade(assignment_id) {
      if (assignment_id in this.assignmentMarks) {
        return this.assignmentMarks[assignment_id];
      } else return "(did not submit)";
    },
    getExamMark(exam_id) {
      if (this.examMarks[exam_id] !== null) {
        return this.examMarks[exam_id];
      } else return "(did not attend)";
    },
    getProjectMark(projectId) {
      if (projectId in this.projectMarks) {
        return this.projectMarks[projectId];
      } else return "(did not submit)";
    },
    async getResult() {
      try {
        let payload = { course_id: this.course_id, batch_id: this.batch_id };
        this.result = await gradeService.get_result(payload);

        if (this.result !== null && this.hasGrades) {
          this.resultPublished = true;
          this.batchExams = this.result.batch_exams;
          this.batchAssignments = this.result.batch_assignments;
          this.batchProjects = this.result.batch_projects;
          this.examMarks =
            "exam_marks" in this.result ? this.result.exam_marks : {};
          this.assignmentMarks =
            "assignment_marks" in this.result
              ? this.result.assignment_marks
              : {};
          this.projectMarks =
            "project_marks" in this.result ? this.result.project_marks : {};
        } else this.resultPublished = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderNow = true;
      }
    },
  },
};
</script>

<style scoped></style>
