<template>
  <grades-component :course_id="course_id" :batch_id="batch_id" />
</template>

<script>
import GradesComponent from "#ef/grades/GradesComponent";
export default {
  name: "Grades",
  components: { GradesComponent },
  props: {
    course_id: {
      Type: String,
      required: true,
    },
    batch_id: {
      Type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
