import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "grades/",

  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */

  async get_result(params) {
    return await $backend
      .post("students_get_batch_result", params)
      .then(checkStatus)
      .then(r => r.data.content.result);
  },

  async get_mark_details(params) {
    return await $backend
      .post("get_student_mark_details", params)
      .then(checkStatus)
      .then(r => r.data.content);
  },
};
