var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.renderNow
    ? _c("div", { attrs: { id: "loading-wrapper" } }, [
        _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
        _c("div", { attrs: { id: "loading-content" } }),
      ])
    : _c(
        "v-container",
        [
          _c("page-title", {
            staticClass: "mt-5",
            attrs: { "page-title": !_vm.$language ? "Grades" : "গ্রেড" },
          }),
          _vm.resultPublished
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "ml-14 mt-14" },
                  [
                    _c("div", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "You have got" : "তুমি") +
                          " " +
                          _vm._s(_vm.result.grade) +
                          " " +
                          _vm._s(!_vm.$language ? "" : "পেয়েছো") +
                          " "
                      ),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { loading: _vm.fetching },
                        on: { click: _vm.viewCertificate },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            !_vm.$language
                              ? "View Certificate"
                              : "সার্টিফিকেট দেখো"
                          )
                        ),
                      ]
                    ),
                    _c(
                      "v-dialog",
                      {
                        model: {
                          value: _vm.preview,
                          callback: function ($$v) {
                            _vm.preview = $$v
                          },
                          expression: "preview",
                        },
                      },
                      [
                        _vm.preview
                          ? _c("file-previewer", {
                              attrs: {
                                "preview-url": _vm.certificate.url,
                                "download-url": _vm.certificate.url,
                                title: !_vm.$language
                                  ? "Certificate"
                                  : "সার্টিফিকেট",
                                "is-video": false,
                                "is-download": false,
                              },
                              on: {
                                cancel: function ($event) {
                                  _vm.preview = false
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-row", { staticClass: "mt-6" }, [
                      _c("span", { staticClass: "ml-4" }, [
                        _vm._v(
                          _vm._s(!_vm.$language ? "Total Grade" : "মোট গ্রেড") +
                            ": "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            staticStyle: { color: "#0099dc" },
                          },
                          [_vm._v(_vm._s(_vm.result.grade))]
                        ),
                      ]),
                      _c("span", { staticClass: "ml-10" }, [
                        _vm._v(
                          _vm._s(!_vm.$language ? "Total Marks" : "মোট নম্বর") +
                            ": " +
                            _vm._s(_vm.result.percentage.toFixed(2)) +
                            "/100"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-14 mt-14" },
                  [
                    _c("div", { staticClass: "mt-6" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$language
                              ? "Detailed Marks"
                              : "বিস্তারিত নম্বর"
                          ) +
                          ": "
                      ),
                    ]),
                    _c("div", { staticClass: "mt-6" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$language ? "Assignments" : "অ্যাসাইনমেন্ট"
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "v-card",
                      { staticClass: "mt-6", attrs: { outlined: "" } },
                      _vm._l(_vm.batchAssignments, function (assignment) {
                        return _c(
                          "div",
                          {
                            key: assignment.object_id,
                            staticClass: "ma-8 ml-6",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(assignment.title) +
                                " (" +
                                _vm._s(assignment.total_score) +
                                ") "
                            ),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.getAssignmentGrade(assignment.object_id)
                                )
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(!_vm.$language ? "Exams" : "পরীক্ষা")),
                    ]),
                    _c(
                      "v-card",
                      { staticClass: "mt-6", attrs: { outlined: "" } },
                      _vm._l(_vm.batchExams.exams, function (exam) {
                        return _c(
                          "div",
                          { key: exam.id, staticClass: "ml-6 my-8" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(exam.title) +
                                " (" +
                                _vm._s(exam.total_points) +
                                ") "
                            ),
                            _c("div", [
                              _vm._v(_vm._s(_vm.getExamMark(exam.id))),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(!_vm.$language ? "Projects" : "প্রজেক্ট")),
                    ]),
                    _c(
                      "v-card",
                      { staticClass: "mt-6", attrs: { outlined: "" } },
                      _vm._l(_vm.batchProjects, function (project) {
                        return _c(
                          "div",
                          { key: project.object_id, staticClass: "ml-6 my-8" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(project.title) +
                                " (" +
                                _vm._s(project.total_score) +
                                ") "
                            ),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.getProjectMark(project.object_id))
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ])
            : _c("no-content-component", {
                staticClass: "mt-14",
                attrs: { message: _vm.noContentMessage },
              }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }